import request from '@/utils/request'

export function fetch(query) {
  return request({
    url: '/v1/tasks',
    method: 'get',
    params: query
  })
}

export function create(data) {
  return request({
    url: '/v1/tasks',
    method: 'post',
    data
  })
}

export function fetchOne(id) {
  return request({
    url: '/v1/tasks/' + id,
    method: 'get',
  })
}

export function fetchClassify(id, query) {
  return request({
    url: '/v1/tasks/' + id + '/classify',
    method: 'get',
    params: query
  })
}

export function restart(id) {
  return request({
    url: '/v1/tasks/' + id + '/restart',
    method: 'post',
  })
}

export function abort(id) {
  return request({
    url: '/v1/tasks/' + id + '/abort',
    method: 'post',
  })
}

export function fetchExport(id) {
  return request({
    url: '/v1/tasks/' + id + '/export',
    method: 'get',
  })
}

export function deleteTask(id) {
  return request({
    url: '/v1/tasks/' + id,
    method: 'delete',
  })
}

export function fetchCategory(id) {
  return request({
    url: '/v1/tasks/' + id + '/category',
    method: 'get',
  })
}

export function fetchItem(id, category) {
  return request({
    url: '/v1/tasks/' + id + '/category/' + category + '/item',
    method: 'get',
  })
}
