<template>
  <div class="tagger-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" label-width="180px">
      <el-form-item :label="$t('tagger.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('tagger.defaultCategory')">
        <el-cascader size="small" :options="categoryList" v-model="form.category" :props="{ checkStrictly: true }"
          clearable></el-cascader>
      </el-form-item>
      <el-form-item :label="$t('tagger.imageSource')">
        <el-radio-group v-model="sourceMode" size="small">
          <el-radio-button label="">{{
            $t("tagger.imageFile")
            }}</el-radio-button>
          <el-radio-button label="urls">{{
            $t("tagger.urlsInExcel")
            }}</el-radio-button>
          <el-radio-button label="excel">{{
            $t("tagger.picsInExcel")
            }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('tagger.uploadImages')" v-if="sourceMode === ''">
        <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
          :headers="{ Authorization: this.token }" list-type="picture-card" :file-list="fileList"
          :on-change="handleChange" :on-remove="handleRemove" :multiple="true">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('tagger.uploadExcel')" v-if="sourceMode === 'urls'">
        <el-upload class="upload-btn" action accept=".csv, .xlsx, .xls" :before-upload="onImportExcel">
          <el-button size="small" icon="el-icon-upload2">{{
            $t("tagger.clickToUpload")
            }}</el-button>
        </el-upload>
        <el-link href="/example-task.xlsx">{{
          $t("common.exampleFile")
          }}</el-link>
        <div class="external-info" v-if="externalList.length > 0">
          <i class="el-icon-info"></i>
          {{ $t("tagger.succUpload", { length: externalList.length }) }}
        </div>
      </el-form-item>
      <el-form-item label="Excel URL" v-if="sourceMode === 'excel'">
        <el-input v-model="form.sourceUrl" size="small" style="width: 60%"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit">{{
          $t("common.submit")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import { create } from "@/api/task";
import XLSX from "xlsx";
export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      categoryList: [],
      form: {
        desc: "",
        category: "",
        sourceUrl: ""
      },
      fileList: [],
      loading: false,
      sourceMode: "", // '', 'urls' / 'excel'
    };
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      this.categoryList = this.$store.getters.t1t2Simple;
    });
  },
  methods: {
    onImportExcel(f) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("processingData"),
      });
      setTimeout(() => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          try {
            const { result } = e.target;
            const workbook = XLSX.read(result, { type: "binary" });
            let data = [];
            for (const sheet in workbook.Sheets) {
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                data = data.concat(
                  XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
                );
              }
            }
            let imageList = data
              .filter((e) => {
                if (!e.ImageURL && !e.URL) {
                  return false;
                }
                return true;
              })
              .map((e, i) => {
                if (i > 100000) {
                  throw new Error("Too many images");
                }
                return {
                  name: e.Name
                    ? e.Name.toString()
                    : e.ID
                      ? e.ID.toString()
                      : "",
                  url: e.ImageURL ? e.ImageURL.toString() : e.URL.toString(),
                  t1: e.Category
                    ? e.Category.toString()
                    : e.T1
                      ? e.T1.toString()
                      : "",
                  t2: e["Item Type"]
                    ? e["Item Type"].toString()
                    : e.T2
                      ? e.T2.toString()
                      : "",
                };
              });
            this.externalList = imageList;
            loading.close();
          } catch (e) {
            loading.close();
            this.$message.error("Load excel error: " + e);
          }
        };
        fileReader.readAsBinaryString(f);
      }, 500);
      return false;
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let params = {
          imageIds: [],
          desc: this.form.desc,
          status: "waiting",
          execType: "tagger",
          isExternalImage: this.sourceMode === 'urls',
          sourceUrl: this.sourceMode === 'excel' ? this.form.sourceUrl : ''
        };
        if (this.form.category && this.form.category.length > 0) {
          params.t1 = this.form.category[0];
          if (this.form.category.length === 2) {
            params.t2 = this.form.category[1];
          }
        }
        let finish = true;
        if (params.isExternalImage) {
          params.images = this.externalList;
        } else {
          this.fileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.imageIds.push(t.response.result[0].id);
            }
          });
        }
        if (!finish) {
          this.$message({
            message: "Image upload not finish, can't submit",
            type: "warning",
          });
        } else if (this.sourceMode === '' && params.imageIds.length === 0) {
          this.$message({
            message: "Upload image please",
            type: "warning",
          });
        } else if (this.sourceMode === 'urls' && params.images.length == 0) {
          this.$message({
            message: "Upload image list please",
            type: "warning",
          });
        } else if (this.sourceMode === 'excel' && params.sourceUrl === '') {
          this.$message({
            message: "Upload Excel please",
            type: "warning",
          });
        } else {
          this.$confirm(
            this.$t("tagger.submitTask"),
            this.$t("common.pleaseConfirm"),
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            this.loading = true;
            create(params)
              .then((response) => {
                this.loading = false;
                if (response.success) {
                  this.$notify({
                    title: this.$t("common.saveSucc"),
                    message: this.$t("common.indirecting"),
                    type: "success",
                  });
                  this.$router.push("/services/tagger").catch(() => { });
                }
              })
              .catch(() => {
                this.loading = false;
              });
          });
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
  },
  watch: {
    sourceMode() {
      this.externalList = [];
      this.fileList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.tagger-new {
  .form {
    margin-top: 25px;
  }

  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list__item-status-label i {
    position: absolute;
    right: 15px
  }

  .external-info {
    color: #666666;
  }

  .upload-btn {
    display: inline-block;
    margin-right: 20px;
  }
}
</style>