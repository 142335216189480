<template>
  <el-breadcrumb class="breadcrumb">
    <el-breadcrumb-item v-for="item in paths" :key="item.name" :to="item.path">{{ item.name }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "AiBreadcrumb",
  computed: {
    paths() {
      switch (this.$route.name) {
        case "Profile":
          return [
            { name: this.$t("route.my") },
            { name: this.$t("route.profile") },
          ];
        case "Order":
          return [
            { name: this.$t("route.my") },
            { name: this.$t("route.order") },
          ];
        case "Tagger":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.tagger") },
          ];
        case "Tagger New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.tagger"), path: "/services/tagger" },
            { name: this.$t("route.new") },
          ];
        case "Tagger Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.tagger"), path: "/services/tagger" },
            { name: this.$t("route.detail") },
          ];
        case "Weekly Board":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.dataCenter") },
            { name: this.$t("route.weeklyBoard") },
          ];
        case "Street Fashion":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.visual") },
            { name: this.$t("route.streetFashion") },
          ];
        case "Window Fashion":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.visual") },
            { name: this.$t("route.windowFashion") },
          ];
        case "Global Trend":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.visual") },
            { name: this.$t("route.globalTrend") },
          ];
        case "Analysis Data Sales":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.dataCenter") },
            { name: this.$t("route.salesData") },
          ];
        case "Analysis Data Stock":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.dataCenter") },
            { name: this.$t("route.stockData") },
          ];
        case "Analysis Best10":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.best20Report") },
          ];
        case "Analysis TPS":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.trendMap") },
          ];
        case "Analysis Matrix":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.trendMatrix") },
          ];
        case "Analysis Best10 Report Generate":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.best20Report"), path: "/services/analysis/best10" },
            { name: this.$t("route.generate") },
          ];
        case "Analysis TPS Report Generate":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.trendMap"), path: "/services/analysis/tps" },
            { name: this.$t("route.generate") },
          ];
        case "Analysis Matrix Report Generate":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.trendMatrix"), path: "/services/analysis/matrix" },
            { name: this.$t("route.generate") },
          ];
        case "Sales Item List":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.salesItem") },
          ];
        case "Sales Trend":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.salesTrend") },
          ];
        case "Weekly Sales":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.weeklySales") },
          ];
        case "Style Search":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.styleSearch") },
          ];
        case "New Product":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.newProduct") },
          ];
        case "Rising Product":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.risingProduct") },
          ];
        case "Product Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.productDetail") },
          ];
        case "Channel":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.channel") },
          ];
        case "Place":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.place") },
          ];
        case "Concept":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.concept") },
          ];
        case "Specific Item":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.specificItem") },
          ];
        case "Analysis Best20 Image":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.analysis") },
            { name: this.$t("route.best20Images") },
          ];
        case "Image Data":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.dataCenter") },
            { name: this.$t("route.imageData") },
          ];
        case "Item Dashboard":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.itemDashboard") },
          ];
        case "Trend Dashboard":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.trendDashboard") },
          ];
        case "Item Compare":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.itemCompare") },
          ];
        case "Trend Compare":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.trendCompare") },
          ];
        case "Item Search":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.itemSearch") },
          ];
        case "Trend Search":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.trendSearch") },
          ];
        case "Item Prediction":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.itemPrediction") },
          ];
        case "Trend Prediction":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.globalTrend") },
            { name: this.$t("route.trendPrediction") },
          ];
        case "Image Trend Map":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.imageTrendMap") },
          ];
        case "Price Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.priceReport") },
          ];
        case "Item Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.itemReport") },
          ];
        case "Color Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.colorReport") },
          ];
        case "Textile Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.textileReport") },
          ];
        case "Consulting Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.consultingReport") },
          ];
        case "Street Trend":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.visual") },
            { name: this.$t("route.streetTrend") },
          ];
        case "Global Trend Report":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.reportConsulting") },
            { name: this.$t("route.globalTrendReport") },
          ];
        case "Window Trend":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.visual") },
            { name: this.$t("route.windowTrend") },
          ];
        case "Search Word":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.nlpAnalysis") },
            { name: this.$t("route.searchWords") },
          ];
        case "Product Name":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.nlpAnalysis") },
            { name: this.$t("route.productName") },
          ];
        case "Popular Elements":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.nlpAnalysis") },
            { name: this.$t("route.popularElements") },
          ];
        case "Product Reviews":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.nlpAnalysis") },
            { name: "Product Reviews" },
          ];
        case "SNS":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.nlpAnalysis") },
            { name: "SNS" },
          ];
        case "Creator":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.aiStyleScene") },
            { name: this.$t("route.creator") },
          ];
        case "GPT New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.aiStyleScene"), path: "/services/gpt/creator" },
            { name: this.$t("route.new") },
          ];
        case "GPT Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.aiStyleScene"), path: "/services/gpt/creator" },
            { name: this.$t("route.detail") },
          ];
        case "My LOOKBOOK":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.aiStyleScene") },
            { name: this.$t("route.myLookbook") },
          ];
        case "AI Design":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDesigner") },
            { name: this.$t("route.aiDesign") },
          ];
        case "AI Design New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDesigner"), path: "/services/gpt-designer/designer" },
            { name: this.$t("route.new") },
          ];
        case "AI Design Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDesigner"), path: "/services/gpt-designer/designer" },
            { name: this.$t("route.detail") },
          ];
        case "Fashion Sketches":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDesigner") },
            { name: this.$t("route.fashionSketches") },
          ];
        case "Model Replace":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.modelAIMorph") },
            { name: this.$t("route.modelReplace") },
          ];
        case "Model Replace New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.modelAIMorph"), path: "/services/gpt-morph/morph" },
            { name: this.$t("route.new") },
          ];
        case "Model Replace Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.modelAIMorph"), path: "/services/gpt-morph/morph" },
            { name: this.$t("route.detail") },
          ];
        case "Model Library":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.modelAIMorph") },
            { name: this.$t("route.modelLibrary") },
          ];
        case "AI Concepter":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIConcepter") },
            { name: this.$t("route.aiConcepter") },
          ];
        case "AI Concepter New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIConcepter"), path: "/services/gpt-concept/concept" },
            { name: this.$t("route.new") },
          ];
        case "AI Concepter Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIConcepter"), path: "/services/gpt-concept/concept" },
            { name: this.$t("route.detail") },
          ];
        case "Concept Sketches":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIConcepter") },
            { name: this.$t("route.conceptSketches") },
          ];
        case "AI Drawing":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDrawing") },
            { name: this.$t("route.aiDrawing") },
          ];
        case "AI Drawing New":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDrawing"), path: "/services/gpt-drawing/drawing" },
            { name: this.$t("route.new") },
          ];
        case "AI Drawing Detail":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDrawing"), path: "/services/gpt-drawing/drawing" },
            { name: this.$t("route.detail") },
          ];
        case "Drawing Book":
          return [
            { name: this.$t("route.service") },
            { name: this.$t("route.fashionAIDrawing") },
            { name: this.$t("route.drawingBook") },
          ];
      }
      return [];
    },
  },
};
</script>

<style>
.breadcrumb {
  float: left;
  line-height: 32px;
}
</style>